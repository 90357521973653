<template>
  <div id="app">
    <side-bar></side-bar>
    <router-view />

  </div>
</template>
<script setup>
let newUrl= window.location.href;
let surl = newUrl.replace('#/home','')
let obj = {}
let urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
  obj[temp[0]] = temp[1];
}

let tokenUrl = ''
let env = null
if(process.env.VUE_APP_API_URL == 'https://llcbdev.lanlingcb.com/dsgc'){
    env = 4
}else if(process.env.VUE_APP_API_URL == 'https://kshtest.lanlingcb.com/dsgc'){
    env = 1
}else if(process.env.VUE_APP_API_URL == 'https://prellcb.kaishuihu.com/dsgc'){
    env = 2
}else if(process.env.VUE_APP_API_URL == 'https://llcb.kaishuihu.com/dsgc'){
    env = 3
}

setTimeout(()=>{
  let token = obj.token.replace('bearer%20',' ')
  let t = token.replace('#/',' ')
  if(window.location.href.indexOf('/ai.kaishuihu.com') > -1){
    tokenUrl = 'https://www.kaishuihu.com/h5/bbq/jb/index.html?jubao=1&user_id='+obj.user_id+'&token='+t+'&env='+env+'&version='+obj.version
  }else{
    tokenUrl = 'https://demo.lanlingcb.com/h5/bbq/jb/index.html?jubao=1&user_id='+obj.user_id+'&token='+t+'&env='+env+'&version='+obj.version
  }
  sessionStorage.setItem('tokenUrl',tokenUrl)
},300)

import SideBar from '@/components/side-bar'
import { onMounted } from 'vue'
import { useUserStore } from './stores/user';
import {judgeInput} from '@/utils/utils'
// import {useKeyboard} from '@/hooks/common.js'
// const {focus}  = useKeyboard()
const userStore = useUserStore()
window.addEventListener('click',function(){
  let dom = this.document.getElementById('input')
  if(dom){
    dom.blur()
  }
})

onMounted(() => {
  userStore.getUser()
  judgeInput()
})



</script>

<style lang="less">
@import url(./common.less);

body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  background: #E8F2FE;
  padding: 0;
  margin: 0;
  // overflow: hidden;
}
</style>
