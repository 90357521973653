import { createRouter, createWebHashHistory } from 'vue-router';
import { useMessageStore } from '@/stores/message.js';
let messageStore
setTimeout(()=>{
  messageStore = useMessageStore()
},100)
const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'message',
    path: '/message',
    component: () => import('./view/home/message.vue'),
    meta: {
      title: '会话',
    },
  },
  {
    name: 'vip',
    path: '/vip',
    component: () => import('./view/vip/vip.vue'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'record',
    path: '/record',
    component: () => import('./view/vip/record.vue'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'memberDetail',
    path: '/memberDetail',
    component: () => import('./view/vip/vip-detail.vue'),
    meta: {
      title: '会员详情',
    },
  },
  {
    name: 'wechat',
    path: '/wechat',
    component: () => import('./view/wechat/index.vue'),
    meta: {
      title: '溜溜壶',
    },
  },
  {
    name: 'activity',
    path: '/activity',
    component: () => import('./view/activity/index.vue'),
    meta: {
      title: '签到活动',
    },
  },
  {
    name: 'actInvite',
    path: '/actInvite',
    component: () => import('./view/actInvite/index.vue'),
    meta: {
      title: '邀请活动',
    },
  },
  {
    name: 'actInvitePrize',
    path: '/actInvite/prize',
    component: () => import('./view/actInvite/prize.vue'),
    meta: {
      title: '邀请活动',
    },
  },
  {
    name: 'actInviteIntro',
    path: '/actInvite/intro',
    component: () => import('./view/actInvite/intro.vue'),
    meta: {
      title: '赚钱攻略',
    },
  },
  {
    name: 'actInviteRule',
    path: '/actInvite/rule',
    component: () => import('./view/actInvite/rule.vue'),
    meta: {
      title: '活动规则',
    },
  },
  {
    name: 'withdrawalRules',
    path: '/actInvite/withdrawalRules',
    component: () => import('./view/actInvite/withdrawalRules.vue'),
    meta: {
      title: '提现规则',
    },
  },{
    name: 'agreementUrl',
    path: '/actInvite/agreementUrl',
    component: () => import('./view/actInvite/agreementUrl.vue'),
    meta: {
      title: '提现服务协议',
    },
  },
  {
    name: 'actInviteShare',
    path: '/actInvite/share',
    component: () => import('./view/actInvite/share.vue'),
    meta: {
      title: '邀请分享',
    },
  },
  {
    name: 'actInviteIncome',
    path: '/actInvite/income',
    component: () => import('./view/actInvite/income.vue'),
    meta: {
      title: '收益明细',
    },
  },
  {
    name: 'appleTree',
    path: '/appleTree',
    component: () => import('./view/appleTree/index.vue'),
    meta: {
      title: '苹果树',
    },
  },
  {
    name: 'medal',
    path: '/appleTree/medal',
    component: () => import('./view/appleTree/medal.vue'),
    meta: {
      title: '苹果勋章',
    },
  },
  {
    name: 'noVisit',
    path: '/appleTree/noVisit',
    component: () => import('./view/appleTree/noVisit.vue'),
    meta: {
      title: '无法查看',
    },
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/register/index.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    name: 'code',
    path: '/register/code',
    component: () => import('./view/register/code.vue'),
    meta: {
      title: '邀请码',
    },
  },
  {
    name: 'list',
    path: '/list',
    component: () => import('./view/list/index.vue'),
    meta: {
      title: '列表',
    },
  },
  {
    name: 'newYear',
    path: '/newYear',
    component: () => import('./view/newYear/index.vue'),
    meta: {
      title: '新年活动',
    },
  },
  {
    name: 'taskCenter',
    path: '/taskCenter',
    component: () => import('./view/taskCenter/index.vue'),
    meta: {
      title: '任务中心',
    },
  },
  {
    name: 'calendar',
    path: '/calendar',
    component: () => import('./view/taskCenter/calendar.vue'),
    meta: {
      title: '签到页面',
    },
  },
  {
    name: 'scoreShop',
    path: '/taskCenter/scoreShop',
    component: () => import('./view/taskCenter/scoreShop.vue'),
    meta: {
      title: '积分商城',
    },
  },
  {
    name: 'income',
    path: '/taskCenter/income',
    component: () => import('./view/taskCenter/income.vue'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'appeal',
    path: '/teenageMode/appeal',
    component: () => import('./view/teenageMode/appeal.vue'),
    meta: {
      title: '青少年模式申诉',
    },
  },
  // {
  //   name: 'test',
  //   path: '/test',
  //   component: () => import('./view/test.vue'),
  //   meta: {
  //     title: '与鸿蒙交互',
  //   },
  // },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    if(from.name === 'message'){
      messageStore.clear()
    }
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
