import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import { router } from './router';
import VueClipboards from 'vue-clipboards';
import 'vant/lib/index.css';
import { Popup, Overlay, Tab, Tabs, Icon, Dialog, List, NavBar, ImagePreview, DatePicker, PullRefresh, RollingText } from 'vant';
// import Vconsole from 'vconsole'

const pinia = createPinia()
const app = createApp(App);

// if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') { // 测试和开发打开，生产不能打开
//   let vConsole = new Vconsole()
//   app.use(vConsole)
// }
app.use(pinia)
app.use(Popup).use(Overlay).use(Tab).use(Tabs).use(Icon).use(Dialog).use(List).use(NavBar).use(ImagePreview).use(DatePicker).use(PullRefresh).use(RollingText);
app.use(VueClipboards)
app.use(router);
app.mount('#app');
