import { defineStore } from 'pinia';
import {getUserInfo} from '@/view/home/api'
let u = navigator.userAgent;
export const useUserStore = defineStore('user           ', {
  state: () => {
    return { 
        showSide:false,
        userInfo:{},
        isPadTop:false,
        oldIcon:0,
        isAndroid: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1,
        isiOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
     };
  },
  // 也可以这样定义
  // state: () => ({ count: 0 })
  actions: {
    getUser(){
      setTimeout(() => {
        if(window.location.href.indexOf('/home') > -1 || window.location.href.indexOf('/message') > -1){
          getUserInfo().then(res=>{
            this.userInfo = res.data
          })
        }
      }, 300);
    },
    setIsPadTop(value){
      this.isPadTop = value
    },
    setOldIcon(value){
      this.oldIcon = value
    }
  },
});
